import { Component, OnInit, Input, ViewChild, Inject, EventEmitter, Output } from '@angular/core';
import { PinchZoomComponent } from 'ngx-pinch-zoom/lib/pinch-zoom.component';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kfa-images-show',
  templateUrl: './images-show.component.html',
  styleUrls: ['./images-show.component.scss']
})
export class ImagesShowComponent implements OnInit {
  content = environment.content;
  selectedItem: any;

  @Input() items: any[];
  @Input() onPopupImage: boolean = false;
  @Input() set ImageSelectPopup(imgSelect: number) {
    if (imgSelect) {
      this.imgSelect = imgSelect;
      let itemFilter = this.items.filter(item => item.AucMedId === imgSelect);
      if (itemFilter && itemFilter.length > 0) {
        this.onSelectItem(itemFilter[0]);
      }
    }
  }
  @Output() openImage = new EventEmitter<any>();
  @ViewChild('pinchZoom', { static: false }) pinchZoom: PinchZoomComponent;
  constructor(@Inject(DOCUMENT) private document: Document) {}
  imgSelect: number = null;

  ngOnInit() {
    if (!this.imgSelect) {
      this.onSelectItem(this.items[0]);
    }
    // for (const item of this.items) {
    //   if (item.PreImg == 'active') return this.onSelectItem(item);
    // }
  }

  onSelectItem(item: any) {
    // ส่งให้เปิด popup ตามที่เลือก
    this.openImage.emit(item.AucMedId);

    if (this.selectedItem && this.selectedItem.AucMedId === item.AucMedId) {
      return;
    }
    this.selectedItem = item;

    // คำนวณเลื่อน scroll ตามรูปที่เลือก
    const imagesEl = this.document.getElementById('images');
    if (imagesEl && imagesEl.scrollWidth > imagesEl.clientWidth) {
      const scrollLeft = imagesEl.scrollLeft;
      const scrollRight = scrollLeft + imagesEl.clientWidth;

      const findIndex = this.items.findIndex(item => this.selectedItem.AucMedId === item.AucMedId);
      // width + border + margin
      const elWidth = 100 + 2 + 16;
      const leftPoint = (findIndex + 1) * elWidth - elWidth;
      const rightPoint = leftPoint + elWidth;
      const centerPoint = (leftPoint + rightPoint) / 2;

      const currentPage = Math.floor(centerPoint / imagesEl.clientWidth);
      imagesEl.scrollLeft = currentPage * imagesEl.clientWidth;
    }

    // ซูมออกเมื่อกำลังซูมอยู่แล้วเปลี่ยนรูป
    if (this.pinchZoom && this.pinchZoom.isZoomedIn) {
      this.pinchZoom.toggleZoom();
    }
  }

  nextImage() {
    const findIndex = this.items.findIndex(item => this.selectedItem.AucMedId === item.AucMedId);
    const nextIndex = findIndex + 1;
    // รูปสุดท้ายแล้วเปลี่ยนเป็นรูปแรก
    if (nextIndex > this.items.length - 1) {
      this.onSelectItem(this.items[0]);
    } else {
      this.onSelectItem(this.items[nextIndex]);
    }
  }

  prevImage() {
    const findIndex = this.items.findIndex(item => this.selectedItem.AucMedId === item.AucMedId);
    const prevIndex = findIndex - 1;
    // รูปแรกแล้วเปลี่ยนเป็นรูปสุดท้าย
    if (prevIndex < 0) {
      this.onSelectItem(this.items[this.items.length - 1]);
    } else {
      this.onSelectItem(this.items[prevIndex]);
    }
  }
}
