import { ValidateDirective } from './directives/validate.directive';
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ModalLoginComponent } from './components/modal-login/modal-login.component';
import { NgxBootstrapModule } from './modules/ngx-bootstrap.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ModalVerifyComponent } from './components/modal-verify/modal-verify.component';
import { CountdownModule } from 'ngx-countdown';
import { ModalRankComponent } from './components/modal-rank/modal-rank.component';
import { EllipsisModule } from 'ngx-ellipsis';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AutosizeModule } from 'ngx-autosize';
import { ImgDirective } from './directives/img.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxGalleryModule } from './modules/ngx-gallery.module';
import { CardComponent } from './components/card/card.component';
import { ModalAuctionDetailComponent } from './components/modal-auction-detail/modal-auction-detail.component';
import { CdCardComponent } from './components/cd-card/cd-card.component';
import { ModalLossComponent } from './components/modal-loss/modal-loss.component';
import { SafePipe } from './pipes/safe.pipe';
import { UserImgDirective } from './directives/user-img.directive';
import { NumberMilPipe } from './pipes/number-mil.pipe';
import { ThaiBahtPipeModule } from 'ngx-thaibahtpipe';
import { CardShopComponent } from './components/card-shop/card-shop.component';
import { CardProductComponent } from './components/card-product/card-product.component';
import { NgBootstrapModule } from './modules/ng-bootstrap.module';
import { ImagesShowComponent } from './components/images-show/images-show.component';
import { ShowPassDirective } from './directives/show-pass.directive';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { NoImgDirective } from './directives/no-img.directive';
import { BtnRippleDirective } from './directives/btn-ripple.directive';
import { HorizontalProductComponent } from './components/horizontal-product/horizontal-product.component';
import { VerticleProductComponent } from './components/verticle-product/verticle-product.component';
import { CarouselModule } from 'primeng-lts/carousel';
import { CarouselProductComponent } from './components/carousel-product/carousel-product.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BestPaginationModule } from './modules/best-pagination/best-pagination.module';
import { BestCdCardComponent } from './components/best-cd-card/best-cd-card.component';
import {GMapModule} from 'primeng/gmap';
import {DropdownModule} from 'primeng-lts/dropdown';
import { AuctionCardComponent } from './components/auction-card/auction-card.component';
import {TabViewModule} from 'primeng/tabview';
import {DialogModule} from 'primeng/dialog';
import { ModalVerifyUserComponent } from './components/modal-verify-user/modal-verify-user.component';
import {TooltipModule} from 'primeng/tooltip';
import { LanguagePipe } from './pipes/language.pipe';
import { ValidateMessageComponent } from './validate-message/validate-message.component';

@NgModule({
  declarations: [
    // * Component
    ModalLoginComponent,
    ModalVerifyComponent,
    ModalRankComponent,
    ModalLossComponent,
    CardComponent,
    ModalAuctionDetailComponent,
    CdCardComponent,
    CardShopComponent,
    CardProductComponent,
    ImagesShowComponent,
    CarouselProductComponent,
    BestCdCardComponent,
    AuctionCardComponent,

    // * Directive
    ImgDirective,
    UserImgDirective,
    ValidateDirective,
    ShowPassDirective,
    NoImgDirective,
    BtnRippleDirective,
    // * Pipe
    SafePipe,
    NumberMilPipe,
    LanguagePipe,
    HorizontalProductComponent,
    VerticleProductComponent,
    ModalVerifyUserComponent,
    ValidateMessageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    // * Custom Module
    NgxBootstrapModule,
    NgBootstrapModule,
    NgxGalleryModule,

    CountdownModule,
    EllipsisModule,
    NgxIntlTelInputModule,
    AutosizeModule,
    NgSelectModule,
    ThaiBahtPipeModule,
    NgBootstrapModule,
    PinchZoomModule,
    CarouselModule,
    GMapModule,
    
    DropdownModule,
    TabViewModule,
    DialogModule,
    TooltipModule
  ],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    BestPaginationModule,

    // * Custom Module
    NgxBootstrapModule,
    NgBootstrapModule,
    NgxGalleryModule,

    CountdownModule,
    EllipsisModule,
    NgxIntlTelInputModule,
    AutosizeModule,
    NgSelectModule,
    ThaiBahtPipeModule,

    // * Component
    ModalLoginComponent,
    ModalVerifyComponent,
    ModalRankComponent,
    ModalLossComponent,
    CardComponent,
    ModalAuctionDetailComponent,
    CdCardComponent,
    AuctionCardComponent,
    CardShopComponent,
    CardProductComponent,
    ImagesShowComponent,
    PinchZoomModule,
    HorizontalProductComponent,
    VerticleProductComponent,
    CarouselProductComponent,
    BestCdCardComponent,

    // * Directive
    ImgDirective,
    UserImgDirective,
    ValidateDirective,
    ShowPassDirective,
    NoImgDirective,
    BtnRippleDirective,
    // * Pipe
    SafePipe,
    NumberMilPipe,
    LanguagePipe,
    GMapModule,

    DropdownModule,
    TabViewModule,
    DialogModule,
    ModalVerifyUserComponent,
    TooltipModule,
    ValidateMessageComponent,
  ],
  providers: [DecimalPipe]
})
export class SharedModule { }
