import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Inbox } from '../interfaces/inbox';
import { Reply } from '../interfaces/reply';

@Injectable({
  providedIn: 'root'
})
export class InboxService {
  inboxHub;

  inbox$ = new BehaviorSubject<Inbox[]>(null);
  replies$ = new BehaviorSubject<Reply[]>(null);
  statusMessage$ = new Subject<{ status: boolean; customerId?: number }>();
  auctionChat$ = new Subject<{AucId: number}>();

  // สำหร้บดักจับ Event ของ Quick message
  quickMessage$ = new Subject<string>();

  // สำหร้บเก็บข้อมูล Quick message เอาไว้ให้ Chat auto send reply
  quickMessage: string;

  constructor() { }

  showMessage(cusId?: number) {
    this.statusMessage$.next({ status: true, customerId: cusId });
  }
  hideMessage() {
    this.statusMessage$.next({ status: false });
  }

  getInbox(customerId: number) {
      return this.inboxHub.server.getInbox(customerId);
  }

  getReplies(inboxId: number = null) {
    return this.inboxHub.server.getReplies(inboxId);
  }

  reply(value: { InboxId: number; Message: string, RefAuction?: string}) {
    return this.inboxHub.server.reply(value);
  }

  editMessage(value: { message: string }, replyId: number) {
    return this.inboxHub.server.editMessage(value, replyId);
  }

  deleteMessage(replyId: number) {
    return this.inboxHub.server.deleteMessage(replyId);
  }

  leaveInbox(inboxId: number) {
    return this.inboxHub.server.leaveInbox(inboxId);
  }

  read(inboxId: number) {
    return this.inboxHub.server.read(inboxId);
  }
}
