<div class="container nav-alert-verify" [ngClass]="{ 'd-none': !alertVerifyUser}">
    <div class="d-flex-verify" [ngClass]="{'tab-hidden': tabHidden === true, 'tab-show': tabHidden === false}">
        <i class="fas fa-exclamation-circle tag-a h24 overflow-hidden" (click)="hiddenTab(false)"></i>
        <p class="ml-3 d-none d-md-block h24 overflow-hidden">{{'please_verify_identity_auction_item' | language}} {{ shop?.Name }}</p>
        <a class="ml-2 h24 overflow-hidden" (click)="onPopupVerifyShop()">{{'more2' | language}}</a>
        <a class="ml-auto" (click)="hiddenTab(true)">{{'hide' | language}}</a>
    </div>
</div>
<nav class="navbar navbar-expand-lg menu-navbar" [ngClass]="{'set-height-navuse': unshowUserMode}">
    <div class="container">
        <a class="navbar-brand" [routerLink]="['/']" *ngIf="!shop">
            <div class="contatiner-set-img">
                <img src="./assets/svg/Logo_DeeAction.svg" alt="logo deeauction" class="logo">
            </div>
        </a>

        <a class="navbar-brand" *ngIf="shop">
            <div class="compo-img-color" (click)="backMainDeeAuc()">
                <img src="./assets/svg/LogoDColor.svg" alt="logo deeauction" class="logo">
            </div>
            <div class="contatiner-set-img d-inline-block" [routerLink]="['/', 'shop', shop.URL]">
                <img *ngIf="shop.Logo" [src]="imageBase + shop.Logo" alt="logo deeauction" class="logo">
                <img *ngIf="!shop.Logo" [src]="nullImageShop" alt="logo deeauction" class="logo">
            </div>
            <p class="d-inline-block ml-2 my-auto name-store" title="{{ shop.Name }}" [routerLink]="['/', 'shop', shop.URL]" >{{ shop.Name }}</p>
        </a>

        <div class="position-relative notify-mobile" style="width: 0px;height: 0px;" *ngIf="customer">
            <div class="position-absolute d-flex position-login">
                <div class="onpage-notify" [ngClass]="{'d-none': !onNotify}">
                    <div class="onpage-compo-i" style="margin-right: unset;transform: translateY(-8px);">
                        <i class="far fa-bell"></i>
                    </div>
                </div>

                <div class="my-notify" [ngClass]="{'d-none': onNotify}">
                    <a data-toggle="dropdown" style="position: relative;" (click)="openpopUp()">
                        <i class="far fa-bell"></i>
                        <div class="compo-bugget" *ngIf="notiData && notiData.UnSeen > 0" >
                            <div class="bugget" [ngClass]="{'min10': notiData.UnSeen < 10 }">
                                <span>{{ notiData.UnSeen < 100 ? notiData.UnSeen : '+99' }}</span>
                            </div>
                        </div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" style="min-width: 230px;">
                        <ng-container *ngIf="notiData && notiData.items" >
                            <ng-container *ngFor="let notiItem of notiItems; let index = index">
                                <a class="dropdown-item " [ngClass]="{'unread-item': !notiItem.Read }" (click)="nextPageNotify(index)">
                                    <div class="component-notify">
                                        <div class="component-icon">
                                            <div class="icon-notify" [ngClass]="{ 'success': notifyCheck('color-success', notiItem.Type), 'error': notifyCheck('color-error', notiItem.Type)}">
                                                <i class="fas fa-shield-alt"  *ngIf="notifyCheck('icon-verify', notiItem.Type)"></i>
                                                <i class="fas fa-user" *ngIf="notifyCheck('icon-joinAuction', notiItem.Type)"></i>
                                                <i class="fas fa-gavel" *ngIf="notifyCheck('icon-auction', notiItem.Type)"></i>
                                                <i class="fas fa-store" *ngIf="notifyCheck('icon-store', notiItem.Type)"></i>
                                                <i class="far fa-credit-card" *ngIf="notifyCheck('icon-card', notiItem.Type)"></i>
                                            </div>
                                        </div>
                                        <div class="component-detail">
                                            <div class="text-detail">
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RV'">
                                                    <strong>{{ notiItem.FromName }}</strong> {{'send_bidder_verification_request_auction' | language}} <strong>{{ notiItem.ToName }}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RVS'">
                                                    {{'bidder_verification_request_auction' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-success"> {{'approved_success' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RVF'">
                                                    {{'bidder_verification_request_auction' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-danger"> {{'rejected' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RB'">
                                                    <strong>{{ notiItem.FromName }} </strong> {{'request_join_auction_item' | language}} <strong> {{ notiItem.ToName }}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RBS'">
                                                    {{'request_join2' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-success"> {{'approved_success' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RBF'">
                                                    {{'request_join2' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-danger"> {{'rejected' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'AS'">
                                                    {{'list' | language}} <strong>{{ notiItem.ToName }}</strong> {{'start_now' | language}}
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'AW'">
                                                    {{'list' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-success"> {{'you_winner' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'AL'">
                                                    {{'list' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-danger"> {{'you_loser' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'MVR'">
                                                    <strong>{{ notiItem.ToName }}</strong> {{'your_shop_sent_verification_request' | language}}
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'MVS'">
                                                    {{'shop_identity_verification_request' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-success"> {{'approved_success' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'MVF'">
                                                    {{'shop_identity_verification_request' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-success"> {{'rejected' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'SP'">
                                                    {{'package_payment_completed' | language}}
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'SA'">
                                                    {{'received_package_admin' | language}}
                                                </span>
                                            </div>
                                            <p class="text-time" *ngIf="notiItem?.settime">{{ notiItem?.settime }}</p>
                                        </div>
                                    </div>
                                </a>
                            </ng-container>
                        </ng-container>
                        <a class="dropdown-item see-all unread-item">
                            <p class="text-center" *ngIf="notiItems && notiItems.length > 0" (click)="nextPageNotify(-1)">{{'more_all' | language}}</p>
                            <p class="text-center" *ngIf="!notiItems || notiItems.length === 0">{{'no_notifications' | language}}</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-expand-menu">
            <span class="navbar-toggler-icon"><i class="fas fa-bars"></i></span>
        </button>

        <div class="collapse navbar-collapse" id="navbar-expand-menu">
            <ul class="navbar-nav">
                <ng-container *ngIf="!customer">
                    <li class="nav-item pc-compo-login-hidden">
                        <a class="nav-link p-0" (click)="openModalLogin()">
                            {{'sign_in' | language}}
                        </a>
                    </li>
                    <li class="nav-item pc-compo-login-hidden">
                        <a class="nav-link p-0" (click)="openModalRegister()">
                            {{'sign_up' | language}}
                        </a>
                    </li>
                    <li class="nav-item pc-compo-login-hidden">
                        <div style="max-width: 108px; margin-right: 12px;">
                            <p-dropdown [options]="languageItems" [(ngModel)]="selectedLanguage" optionLabel="name"
                                class="language-select" [filter]="false" filterBy="name" [showClear]="false"
                                placeholder="Select a Language" (onChange)="languageUse($event)">
                                <ng-template pTemplate="selectedItem">
                                    <div class="" *ngIf="selectedLanguage">
                                        <img [src]="selectedLanguage.src" height="16px" />
                                        <div class="name-language">{{selectedLanguage.name}}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-language pTemplate="item">
                                    <div class="">
                                        <img [src]="language.value.src" height="16px" [class]="" />
                                        <div class="name-language">{{language.value.name}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </li>
                </ng-container>

                <ng-container *ngIf="customer">
                    <li  class="nav-item dropdown pc-compo-login-hidden text-center">
                        <img class="img-customer" *ngIf="!urlImage" [src]="noUserImg" alt="" />
                        <img class="img-customer" *ngIf="urlImage" [src]="urlImage" alt="" />
                    </li>
                    <ng-container *ngFor="let itemD of dropdownItem">
                        <li  class="nav-item dropdown pc-compo-login-hidden"
                            data-toggle="collapse" 
                            data-target="#navbar-expand-menu"    
                            routerLinkActive="active"
                            [routerLink]="itemD.link">
                            <a class="nav-link p-0" routerLinkActive="active" [routerLink]="itemD.link">
                                <i [className]="itemD.class"></i> {{ itemD.title }}
                            </a>
                        </li>
                    </ng-container>
                    <li  class="nav-item dropdown pc-compo-login-hidden">
                        <div style="max-width: 108px; margin-right: 12px;">
                            <p-dropdown [options]="languageItems" [(ngModel)]="selectedLanguage" optionLabel="name"
                                class="language-select" [filter]="false" filterBy="name" [showClear]="false"
                                placeholder="Select a Language" (onChange)="languageUse($event)">
                                <ng-template pTemplate="selectedItem">
                                    <div class="" *ngIf="selectedLanguage">
                                        <img [src]="selectedLanguage.src" height="16px" />
                                        <div class="name-language">{{selectedLanguage.name}}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-language pTemplate="item">
                                    <div class="">
                                        <img [src]="language.value.src" height="16px" [class]="" />
                                        <div class="name-language">{{language.value.name}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </li>
                    <li  class="nav-item dropdown pc-compo-login-hidden" (click)="onLogout()">
                        <a class="nav-link p-0">
                            <i class="fas fa-sign-out-alt"></i> {{'logout' | language}}
                        </a>
                    </li>
                </ng-container>
            </ul>
        </div>

        <div class="position-relative pc-compo-login-show" style="width: 0px;height: 0px;" *ngIf="customer">
            <div class="position-absolute d-flex position-login">
                <div style="max-width: 108px; margin-right: 12px;">
                    <p-dropdown [options]="languageItems" [(ngModel)]="selectedLanguage" optionLabel="name"
                        class="language-select" [filter]="false" filterBy="name" [showClear]="false"
                        placeholder="Select a Language" (onChange)="languageUse($event)">
                        <ng-template pTemplate="selectedItem">
                            <div class="" *ngIf="selectedLanguage">
                                <img [src]="selectedLanguage.src" height="16px" />
                                <div class="name-language">{{selectedLanguage.name}}</div>
                            </div>
                        </ng-template>
                        <ng-template let-language pTemplate="item">
                            <div class="">
                                <img [src]="language.value.src" height="16px" [class]="" />
                                <div class="name-language">{{language.value.name}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="onpage-notify" [ngClass]="{'d-none': !onNotify}">
                    <div class="onpage-compo-i">
                        <i class="far fa-bell"></i>
                    </div>
                </div>

                <div class="my-notify" [ngClass]="{'d-none': onNotify}">
                    <a data-toggle="dropdown" style="position: relative;" (click)="openpopUp()">
                        <i class="far fa-bell"></i>
                        <div class="compo-bugget" *ngIf="notiData && notiData.UnSeen > 0" >
                            <div class="bugget" [ngClass]="{'min10': notiData.UnSeen < 10 }">
                                <span>{{ notiData.UnSeen < 100 ? notiData.UnSeen : '+99' }}</span>
                            </div>
                        </div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" style="min-width: 230px;">
                        <ng-container *ngIf="notiData && notiData.items" >
                            <ng-container *ngFor="let notiItem of notiItems; let index = index">
                                <a class="dropdown-item " [ngClass]="{'unread-item': !notiItem.Read }" (click)="nextPageNotify(index)">
                                    <div class="component-notify">
                                        <div class="component-icon">
                                            <div class="icon-notify" [ngClass]="{ 'success': notifyCheck('color-success', notiItem.Type), 'error': notifyCheck('color-error', notiItem.Type)}">
                                                <i class="fas fa-shield-alt"  *ngIf="notifyCheck('icon-verify', notiItem.Type)"></i>
                                                <i class="fas fa-user" *ngIf="notifyCheck('icon-joinAuction', notiItem.Type)"></i>
                                                <i class="fas fa-gavel" *ngIf="notifyCheck('icon-auction', notiItem.Type)"></i>
                                                <i class="fas fa-store" *ngIf="notifyCheck('icon-store', notiItem.Type)"></i>
                                                <i class="far fa-credit-card" *ngIf="notifyCheck('icon-card', notiItem.Type)"></i>
                                            </div>
                                        </div>
                                        <div class="component-detail">
                                            <div class="text-detail">
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RV'">
                                                    <strong>{{ notiItem.FromName }}</strong> {{'send_bidder_verification_request_auction' | language}} <strong>{{ notiItem.ToName }}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RVS'">
                                                    {{'bidder_verification_request_auction' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-success"> {{'approved_success' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RVF'">
                                                    {{'bidder_verification_request_auction' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-danger"> {{'rejected' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RB'">
                                                    <strong>{{ notiItem.FromName }} </strong> {{'request_join_auction_item' | language}} <strong> {{ notiItem.ToName }}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RBS'">
                                                    {{'request_join2' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-success"> {{'approved_success' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RBF'">
                                                    {{'request_join2' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-danger"> {{'rejected' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'AS'">
                                                    {{'list' | language}} <strong>{{ notiItem.ToName }}</strong> {{'start_now' | language}}
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'AW'">
                                                    {{'list' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-success"> {{'you_winner' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'AL'">
                                                    {{'list' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-danger"> {{'you_loser' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'MVR'">
                                                    <strong>{{ notiItem.ToName }}</strong> {{'your_shop_sent_verification_request' | language}}
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'MVS'">
                                                    {{'shop_identity_verification_request' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-success"> {{'approved_success' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'MVF'">
                                                    {{'shop_identity_verification_request' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-success"> {{'rejected' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'SP'">
                                                    {{'package_payment_completed' | language}}
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'SA'">
                                                    {{'received_package_admin' | language}}
                                                </span>
                                            </div>
                                            <p class="text-time" *ngIf="notiItem?.settime">{{ notiItem?.settime }}</p>
                                        </div>
                                    </div>
                                </a>
                            </ng-container>
                        </ng-container>
                        <a class="dropdown-item see-all unread-item">
                            <p class="text-center" *ngIf="notiItems && notiItems.length > 0" (click)="nextPageNotify(-1)">{{'more_all' | language}}</p>
                            <p class="text-center" *ngIf="!notiItems || notiItems.length === 0">{{'no_notifications' | language}}</p>
                        </a>
                    </div>
                </div>
                <div class="my-profile">
                    <a data-toggle="dropdown">
                        <img class="img-customer" *ngIf="urlImage" [src]="urlImage" alt="" />
                        <img class="img-customer" *ngIf="!urlImage" [src]="noUserImg" alt="" />
                    </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown-img-profile" style="min-width: 230px;">
                        <ng-container *ngIf="dropdownItem">
                            <a *ngFor="let itemD of dropdownItem" class="dropdown-item d-flex" routerLinkActive="active"
                                [routerLink]="itemD.link" style="height: 40px;">
                                <i [className]="itemD.class + ' my-auto mr-2'"></i>
                                <p class="my-auto">{{ itemD.title }}</p>
                            </a>
                        </ng-container>
                        <a class="dropdown-item d-flex" (click)="onLogout()">
                            <span class="material-icons-outlined my-auto" style="color: #32324D;">
                                logout
                            </span>
                            <p class="my-auto">{{'sign_out' | language}}</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="position-relative pc-compo-login-show" style="width: 0px;height: 0px;" *ngIf="!customer">
            <div class="position-absolute d-flex position-login">
                <div style="max-width: 108px; margin-right: 12px;">
                    <p-dropdown [options]="languageItems" [(ngModel)]="selectedLanguage" optionLabel="name"
                        class="language-select" [filter]="false" filterBy="name" [showClear]="false"
                        placeholder="Select a Language" (onChange)="languageUse($event)">
                        <ng-template pTemplate="selectedItem">
                            <div class="" *ngIf="selectedLanguage">
                                <img [src]="selectedLanguage.src" height="16px" />
                                <div class="name-language">{{selectedLanguage.name}}</div>
                            </div>
                        </ng-template>
                        <ng-template let-language pTemplate="item">
                            <div class="">
                                <img [src]="language.value.src" height="16px" [class]="" />
                                <div class="name-language">{{language.value.name}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <button type="button" class="btn btn-outline-primary" (click)="openModalLogin()">{{'sign_in' | language}}</button>
                <button type="button" class="btn btn-primary" (click)="openModalRegister()">{{'sign_up' | language}}</button>
            </div>
        </div>
    </div>
</nav>




<ng-template #loginModal>
    <kfa-modal-login [modalLogin]="modalRefLogin" (onLoadModal)="onLoadModalLoginComponent($event)"></kfa-modal-login>
</ng-template>

<ng-template #rankModal>
    <kfa-modal-rank [shopURL]="urlShop" (Close)="closeModal()"></kfa-modal-rank>
</ng-template>

<ng-template #lossModal>
    <kfa-modal-loss (Close)="closeModal()"></kfa-modal-loss>
</ng-template>

<ng-template #verifyModal>
    <kfa-modal-verify [modalVerify]="modalRefVerify" [fixPhone]="true"> </kfa-modal-verify>
</ng-template>

<best-modal-verify-user [Setdisplay]="{display:dVerifyUser, urlShop: urlShop }"
    (onOut)="onCloseVerifyUser($event)"></best-modal-verify-user>