import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from './services/local-storage.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { KEY_TOKEN } from '../models/app.models';
import moment from 'moment';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(public storage: LocalStorageService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = '';

    // for backend test api
    const API_HOST = this.storage.get('API_HOST');
    if (environment.name === 'develop' && API_HOST) {
      url = `${API_HOST}api/ms/${request.url}`;
    } else {
      url = `${environment.api}api/ms/${request.url}`;
    }
    // for funkin browser cache
    url += url.search('\\?') >= 0 ? '&' : '?';
    url += `ramd=${this.dateTimeNumber}`;

    request = request.clone({
      url: url
    });

    let headers: any = {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache'
    };

    if (this.storage.get(KEY_TOKEN)) {
      headers.Authorization = this.storage.get(KEY_TOKEN);
    }

    if (localStorage.getItem('language')) {
      headers.lang = localStorage.getItem('language');
    }


    request = request.clone({
      setHeaders: headers
    });

    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }
            this.storage.clear();
            this.router.navigate(['']);
          }
        }
      )
    );
  }

  get dateTimeNumber(): number {
    const date = moment().format('YYYYMMDDHHmmss');
    return +date;
  }

  get randomNumber() {
    return Math.round(Math.random() * 100000);
  }
}
