<p-dialog [(visible)]="display" styleClass="verify-popup" [modal]="true" [baseZIndex]="10000" [draggable]="false" (onHide)="onClose(false)"
    [resizable]="false">
    <p-header>
        {{'more2' | language}}
    </p-header>
    <div class="row p-2 m-0" *ngIf="verified_note && verified_note.length > 0">
        <div class="col-12 note-compo py-2" [ngClass]="{'note-error': verified_status === 'F' , 'd-none' : verified_status === 'W' || verified_status === 'P' }">
            <p class="title-note">{{'message_to_user' | language}} : </p>
            <p class="detail-note">{{ verified_note }}</p>
        </div>
    </div>
    <div class="row p-2 m-0" *ngIf="verified_status === 'P'">
        <div class="col-12 note-compo note-warning py-2" >
            <p class="detail-note">{{'pending' | language}}</p>
        </div>
    </div>
    <ng-container *ngIf="form">
        <form [formGroup]="form">
            <div class="row p-2 m-0" style="width: 100%;" formArrayName="evidences">
                <ng-container *ngFor="let items of getFormArray(form, 'evidences').controls; let i = index" [formGroupName]="i">
                    <div class="col-12" *ngIf="items?.value.evidence_type === 'T'">
                        <div class="form-group">
                            <label>{{ items?.value.evidence_name }}</label>
                            <input type="text" class="form-control" formControlName="evidence_value" />
                            <best-validate-message [control]="items.controls['evidence_value']"></best-validate-message>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="items?.value.evidence_type === 'I'">
                        <div class="form-group">
                            <label for="">{{ items?.value.evidence_name }}</label>
                        </div>
                        <ng-container *ngIf="items?.value.evidence_value">
                            <div class="position-relative compo-show-image">
                                <i class="fas fa-trash-alt bin-img" (click)="removeImg(i, inputFile)"></i>
                                <img *ngIf="items?.value.local" [src]="content + items?.value.evidence_value" class="profile-img m-auto" />
                                <img *ngIf="!items?.value.local" [src]="items?.value.evidence_value" class="profile-img m-auto" />
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!items?.value.evidence_value">
                            <label [for]="'inputFileUpload'+i" class="image-ppp"><i class="far fa-id-card"></i> {{'upload' | language}} </label>
                        </ng-container>
                        <input [id]="'inputFileUpload'+i" type="file" class="d-none" accept="image/*" (change)="onSelectFile($event, inputFile, i)" #inputFile/>
                    </div>
                    <div class="col-12" *ngIf="items?.value.evidence_type === 'F'">
                        <div class="form-group">
                            <label for="" class="mb-0">{{ items?.value.evidence_name }}</label>
                            <p class="text-danger">(.pdf)</p>
                        </div>
                        <ng-container *ngIf="items?.value.evidence_value">
                            <div class="position-relative compo-show-image show-file">
                                <i class="fas fa-trash-alt bin-img" (click)="removeImg(i, inputFileData, 'file')"></i>
                                <a *ngIf="!items?.value.local" [href]="safeFile(items?.value.evidence_value)" download="">{{ items?.value.evidence_name_file }}</a>
                                <a *ngIf="items?.value.local && content" [href]="content + items?.value.evidence_value" download="">{{ items?.value.evidence_name_file }}</a>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!items?.value.evidence_value">
                            <label [for]="'inputFileUpload'+i" class="image-ppp file-ppp"><i class="fas fa-file-alt"></i> {{'upload' | language}} </label>
                        </ng-container>
                        <input [id]="'inputFileUpload'+i" type="file" class="d-none" (change)="onSelectFile($event, inputFileData, i, 'file')" #inputFileData/>
                    </div>
                </ng-container>
            </div>
            <div class="p-4">
                <button type="button" class="btn btn-primary" (click)="onSubmit()">{{'save' | language}}</button>
                <button type="button" class="btn btn-light btn-exit ml-3" (click)="onClose(false)">{{'cancel' | language}}</button>
            </div>
        </form>
    </ng-container>

</p-dialog>