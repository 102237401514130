import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from 'src/app/app.service';
import { ShopService } from 'src/app/services/shop.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LanguagePipe } from '../../pipes/language.pipe';

@Component({
  selector: 'best-modal-verify-user',
  templateUrl: './modal-verify-user.component.html',
  styleUrls: ['./modal-verify-user.component.scss'],
  providers: [LanguagePipe]
})
export class ModalVerifyUserComponent implements OnInit {
  @Input() set Setdisplay(data: any) {
    if (data.display) {
      this.createForm();
      this.display = true;
      this.urlShop = data.urlShop;
      this.getUserVerify();
    } else {
      this.display = false;
    }
  }; 
  @Output() onOut = new EventEmitter<any>();

  display: boolean = false;
  form: FormGroup;
  imgLocal: boolean;
  content = environment.content;
  noUserImg = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8Cuyq7AdKhhmAL6ceV-s8oLT630oo9uMOWPjH994GigCH5S3q';
  urlShop: string;
  verified_note: string = '';
  verified_status: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private svApp: AppService,
    private shopSv: ShopService,
    private dom: DomSanitizer,
    private languageSer: LanguagePipe,
    ) {
    this.createForm();
  }

  ngOnInit() {
  } 

  createForm() {
    this.form = this.formBuilder.group({
      evidences: this.formBuilder.array([])
    });
  }

  getFormArray(form: FormGroup, name: string) {
    return form.get(name) as FormArray;
  }

  /** ดึงข้อมูล verify ของลูกค้า */
  getUserVerify() {
    this.shopSv.getUserVerify(this.urlShop).subscribe(
      (res: any) => {
      

        this.verified_note = res.verified_note;
        this.verified_status = res.verified_status;
        res.evidences.forEach(items => {
          let formdata: any;
          if (items.evidence_type === 'I') {
            formdata = this.formBuilder.group({
              evidence_id: items.evidence_id,
              evidence_name: items.evidence_name,
              evidence_type: items.evidence_type,
              evidence_value: items.evidence_value,
              local: true,
            });
            formdata.get('evidence_value').setValidators(Validators.required);
          } else if (items.evidence_type === 'F') {
            formdata = this.formBuilder.group({
              evidence_id: items.evidence_id,
              evidence_name: items.evidence_name,
              evidence_type: items.evidence_type,
              evidence_value: items.evidence_value,
              evidence_name_file: items.evidence_name_file,
              local: true,
            });
            formdata.get('evidence_name_file').setValue(this.languageSer.transform('document'));
            formdata.get('evidence_value').setValidators(Validators.required);
          } else {
            formdata = this.formBuilder.group({
              evidence_id: items.evidence_id,
              evidence_name: items.evidence_name,
              evidence_type: items.evidence_type,
              evidence_value: items.evidence_value,
              evidence_limit_amount: items.evidence_limit_amount,
              local: false,
            });
            formdata.get('evidence_value').setValidators([Validators.required, Validators.maxLength(items.evidence_limit_amount)]);
          }
          
          this.getFormArray(this.form, 'evidences').push(formdata);
        });
      }, err => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: `${err}`,
          showConfirmButton: false,
          timer: 1500
        });
      }
    );
  }

  async onSelectFile(event, inputfile: HTMLInputElement, i: number, functionName?: string) {
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      const typefile = file.type.split('/');
      if (functionName === 'file') {
        this.getFormArray(this.form, 'evidences').controls[i].get('evidence_name_file').setValue(file.name);
      }
      if ((functionName !== 'file' && (typefile[1] === 'png' || typefile[1] === 'jpeg')) || (functionName === 'file' && typefile[1] === 'pdf')) {
        this.toBase64(file).then(res => {
          this.getFormArray(this.form, 'evidences').controls[i].get('evidence_value').setValue(res);
          this.getFormArray(this.form, 'evidences').controls[i].get('local').setValue(false);
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: this.languageSer.transform('language005'),
          showConfirmButton: false,
          timer: 1500
        });

        inputfile.value = '';
      }
    }
  }

  toBase64(file) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  removeImg(i: number, inputfile: HTMLInputElement, functionName?: string) {
    inputfile.value = '';
    this.getFormArray(this.form, 'evidences').controls[i].get('local').setValue(false);
    this.getFormArray(this.form, 'evidences').controls[i].get('evidence_value').setValue(null);
    if (functionName === 'file') {
      this.getFormArray(this.form, 'evidences').controls[i].get('evidence_name_file').setValue(null);
    }
  }

  onSubmit() {
    let dataForm = this.form.value;
    let checkNull = false;
    for (let i = 0; i < dataForm.evidences.length; i++) {
      this.getFormArray(this.form, 'evidences').controls[i].get('evidence_value').markAllAsTouched();
      if (!dataForm.evidences[i].evidence_value) {
        checkNull = true;
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: this.languageSer.transform('please_enter_your_information_correctly_and_completely'),
          showConfirmButton: false,
          timer: 1500
        });
        this.form.markAllAsTouched();
        break;
      } else if (dataForm.evidences[i].evidence_value && dataForm.evidences[i].evidence_limit_amount &&
        dataForm.evidences[i].evidence_value.length > dataForm.evidences[i].evidence_limit_amount ) {
        checkNull = true;
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: `${dataForm.evidences[i].evidence_name}  ${this.languageSer.transform('cannot_enter_more_than')} ${dataForm.evidences[i].evidence_limit_amount} ${this.languageSer.transform('character')}`,
          showConfirmButton: false,
          timer: 2000
        });
        this.form.markAllAsTouched();
        break;
      }
    }
    if (!checkNull) {
      this.shopSv.putUserVerify({url_name: this.urlShop, evidences: dataForm.evidences}).subscribe(
        res => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: this.languageSer.transform('send_authentication_information_successfully'),
            showConfirmButton: false,
            timer: 1500
          });
          this.onClose(true);
        }, err => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: `${err.error.Message}`,
            showConfirmButton: false,
            timer: 1500
          });
        }
      );
    }
  }

  onClose(save: boolean) {
    this.onOut.emit({display: false, save: save});
    this.display = false;
  }

  openFile(file: any) {
    window.open(file, '_blank');
  }

  safeFile(fileName: string) {
    return this.dom.bypassSecurityTrustUrl(fileName);
  }

}
