import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { AuctionPageFilterNamespace, AuctionPageNamespace } from '../interfaces/auction';

@Injectable({
  providedIn: 'root'
})
export class AuctionService {
  auctionHub: any;
  Auction: any;
  title: string;

  auctionStarted = new BehaviorSubject<boolean>(false);
  auctionSubscription = new Subject<any>();
  chatSubscription = new BehaviorSubject<any[]>([]);
  finishSubscription = new Subject<any>();
  cancelSubscription = new Subject<any>();
  customerLobbyStatus = new Subject<string>();
  customerLobbyRequest = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient, private svMeta: Meta) { }

  GetAuctionList() {
    return this.http.get<any>(`auction/list`);
  }

  GetAuctionById(AuctionId) {
    return this.http.get<any>(`auction/get/${AuctionId}`);
  }

  GetAuctionByCode(auctionCode) {
    return this.http.get<IAuction>(`auction/get/auctioncode/${auctionCode}`);
  }

  /** 
   * ดึงข้อมูลการประมูลรถ
   * POST api/ms/product/infomation/advancedsearch/auctions?statusAuction={statusAuction}
   *  */
  GetBestAuctionAsTable(filters = new AuctionPageFilterNamespace.FilterClass()) {
    const queryString1 = `?statusAuction=${filters.statusAuction}`;
    const queryString2 = `page=${filters.pageAuction}&limit=${filters.limitAuction}`;
    const actionURL = `product/infomation/advancedsearch/auctions${queryString1}&${queryString2}`;
    return this.http
      .post<AuctionPageNamespace.IResponse>(actionURL, filters.filterAuction);
  }

  /** 
  * ดึงข้อมูลการประมูลรถ version 2
  * GET api/ms/product/infomation/find/dashboard/auctions
  *  */
  GetBestAuctionAsTableV2() {
    return this.http.get<AuctionPageNamespace.IResponse>('product/infomation/find/dashboard/auctions');
  }

  GetAuctionAsTable(pagi) {
    const filterParams = `auctiontypeId=${pagi.typeId}&status=${pagi.status}`;
    const pageParams = `page=${pagi.page}&limit=${pagi.limit}&name=${pagi.name}&filter=${pagi.filter}`;
    return this.http.get<any>(`auction/table?${pageParams}&${filterParams}`);
  }

  getDashboardWinner() {
    return this.http.get<any>('auction/get/dashboard/winner');
  }

  /**
   * Dashboard Owner
   */
  GetAuctionPagiOwner(pagi) {
    const pageParams = `page=${pagi.page}&limit=${pagi.limit}&name=${pagi.name}&filter=${pagi.filter}`;
    const pageFilter = `status=${pagi.status}&firstdt=${pagi.firstdt}&seconddt=${pagi.seconddt}&search=${pagi.search}`;
    return this.http.get<IPagination<IAuction>>(`auction/pagination/byowner?${pageFilter}&${pageParams}`);
  }

  /**
   * Dashboard Winner
   */
  GetAuctionPagiHistoryBid(pagi) {
    const pageParams = `page=${pagi.page}&limit=${pagi.limit}&name=${pagi.name}&filter=${pagi.filter}`;
    const pageFilter = `status=${pagi.status}&firstdt=${pagi.firstdt}&seconddt=${pagi.seconddt}&search=${pagi.search}&storeURL=${pagi.storeURL}`;
    return this.http.get<IPagination<IAuction>>(`auction/pagination/historybid/bycustomer?${pageFilter}&${pageParams}`);
  }

  PostAuction(model) {
    return this.http.post<any>(`auction/create`, model);
  }

  PutAuction(AuctionId, model) {
    return this.http.put<any>(`auction/edit/${AuctionId}`, model);
  }

  PutAuctionCancel(AuctionId) {
    return this.http.put<any>(`auction/cancel/${AuctionId}`, {});
  }

  GetAuctionTop(auctionId) {
    return this.http.get<any>(`auction/topcustomer/${auctionId}`);
  }

  PostAuctionBid(auctionId, model) {
    return this.http.post<any>(`auction/bid/${auctionId}`, model);
  }

  GetAuctionServicePrice() {
    return this.http.get<any>('setting/serviceprice');
  }

  setTag() {
    return new Promise(resolve => {
      this.svMeta.updateTag(
        {
          name: 'description',
          content:
            'ระบบประมูลสินค้าสำเร็จรูป ติดตั้งแบบ On-premise หรือ แบบเช่า subscription มีร้านค้าเป็นของตัวเองได้'
        },
        'name="description"'
      );
      this.svMeta.updateTag(
        {
          name: 'keywords',
          content: `ระบบประมูลสินค้า, ระบบประมูลแบบเช่า, ประมูลสินค้าออนไลน์, online auction system, ซอฟต์แวร์ระบบประมูล`
        },
        'name="keywords"'
      );
      resolve(true);
    });
  }

  /** ดึงข้อมูลรายละเอียดของ Auto bit proxy */
  GetAuctionBitProxyDetail(auctionId: number) {
    return this.http.get<number>(`auction/bid/bidseq/bybidder/${auctionId}`);
  }
}
