import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../core/services/local-storage.service';
import { AuthenticationService } from '../core/services/authentication.service';
import { finalize } from 'rxjs/operators';
import { CustomerService } from 'src/app/services/customer.service';
import { KEY_TOKEN, KEY_USER } from '../models/app.models';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private storage: LocalStorageService,
    private auth: AuthenticationService,
    private svCustomer: CustomerService,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.storage.get(KEY_TOKEN)) {
        // this.clearAuth();
        return resolve(true);
      }
      this.auth
        .checkToken(this.storage.get(KEY_TOKEN))
        .pipe(finalize(() => resolve(true)))
        .subscribe(
          res => {
            if (!res) {
              this.clearAuth();
            }
          },
          _ => {
            this.clearAuth();
          }
        );
    });
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

  clearAuth() {
    this.storage.clear();
    this.svCustomer.Customer$.next(null);
  }
}
