// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   name: 'develop',
//   api: 'https://dev-api.koiauction1.com/',
//   apiv2: 'https://dev-api.koiauction1.com:5200/',
//   // content: 'https://dev-api.koiauction1.com/images/',
//   content: 'https://dev.koiauction1.com:8123/ImagesDev/',
//   contentv2: 'https://dev-api.koiauction1.com:5200/images/',
// };

export const environment = {
  production: false,
  name: 'develop',
  api: 'https://api-dev.dee.auction/',
  apiv2: 'https://api-dev.dee.auction/',
  content: 'https://content-dev.dee.auction/',
  contentv2: 'https://content-dev.dee.auction/',
  apibo: 'https://bo-dev.dee.auction',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
