import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicComponent } from './layouts/public/public.component';
import { NoAuthGuard } from './guards/no-auth.guard';
import { AuthGuard } from './guards/auth.guard';
import { AppURL } from './app.url';

// const routes: Routes = [
//   {
//     path: '',
//     canActivate: [NoAuthGuard],
//     component: PublicComponent,
//     loadChildren: () => import('./modules/home-page/home-page.module').then(m => m.HomePageModule),
//     data: {
//       title: 'ประมูลปลาคาร์ฟ ฟาร์มปลาคาร์ฟเพสโต้ ปลาคาร์ฟญี่ปุ่น | Pesto Koi Farm'
//     }
//   },
//   {
//     path: 'payment',
//     canActivate: [NoAuthGuard],
//     component: PublicComponent,
//     loadChildren: () => import('./modules/payment-page/payment-page.module').then(m => m.PaymentPageModule),
//     data: {
//       title: ''
//     }
//   },
//   {
//     path: 'hall-of-fame',
//     canActivate: [NoAuthGuard],
//     component: PublicComponent,
//     loadChildren: () =>
//       import('./modules/hall-of-fame-page/hall-of-fame-page.module').then(m => m.HallOfFamePageModule),
//     data: {
//       title: 'ห้องประมูลปลาคาร์ฟออนไลน์ จากฟาร์มปลาคุณภาพสูง'
//     }
//   },
//   {
//     path: 'news',
//     canActivate: [NoAuthGuard],
//     component: PublicComponent,
//     loadChildren: () => import('./modules/news-page/news-page.module').then(m => m.NewsPageModule),
//     data: {
//       title: 'ข่าวสารเกี่ยวกับปลาคาร์ฟ และ วงการประมูลปลา'
//     }
//   },
//   {
//     path: 'auctions',
//     canActivate: [NoAuthGuard],
//     component: PublicComponent,
//     loadChildren: () => import('./modules/auction-page/auction-page.module').then(m => m.AuctionPageModule),
//     data: {
//       title: 'ประมูลปลาคาร์ฟ ระบบประมูลปลาคาร์ฟออนไลน์ | Koi Online Auction'
//     }
//   },
//   {
//     path: 'auction',
//     canActivateChild: [NoAuthGuard],
//     component: PublicComponent,
//     loadChildren: () => import('./modules/auction-detail/auction-detail.module').then(m => m.AuctionDetailModule),
//     data: {
//       title: ''
//     }
//   },
//   {
//     path: 'shop',
//     canActivateChild: [NoAuthGuard],
//     component: PublicComponent,
//     loadChildren: () => import('./modules/shop-page/shop-page.module').then(m => m.ShopPageModule),
//     data: {
//       title: ''
//     }
//   },
//   {
//     path: 'about',
//     canActivate: [NoAuthGuard],
//     component: PublicComponent,
//     loadChildren: () => import('./modules/about-page/about-page.module').then(m => m.AboutPageModule),
//     data: {
//       title: ''
//     }
//   },
//   {
//     path: 'contact',
//     canActivate: [NoAuthGuard],
//     component: PublicComponent,
//     loadChildren: () => import('./modules/contact-us-page/contact-us-page.module').then(m => m.ContactUsPageModule),
//     data: {
//       title: ''
//     }
//   },
//   {
//     path: 'how-to',
//     canActivate: [NoAuthGuard],
//     component: PublicComponent,
//     loadChildren: () => import('./modules/how-to-page/how-to-page.module').then(m => m.HowToPageModule),
//     data: {
//       title: ''
//     }
//   },
//   // {
//   //   path: 'cart',
//   //   canActivate: [NoAuthGuard],
//   //   component: PublicComponent,
//   //   loadChildren: () => import('./modules/cart/cart.module').then(m => m.CartModule),
//   //   data: {
//   //     title: ''
//   //   }
//   // },
//   // {
//   //   path: 'notification',
//   //   component: PublicComponent,
//   //   loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule),
//   //   data: {
//   //     title: ''
//   //   }
//   // },
//   {
//     path: 'managements',
//     canActivate: [AuthGuard],
//     component: PublicComponent,
//     loadChildren: () => import('./modules/your-management/your-management.module').then(m => m.YourManagementModule),
//     data: {
//       title: ''
//     }
//   },
//   {
//     path: '**',
//     redirectTo: '',
//     pathMatch: 'full'
//   }
// ];

const bestRoutes: Routes = [
  {
    path: '',
    canActivate: [NoAuthGuard],
    component: PublicComponent,
    // loadChildren: () => import('./modules/best-car-pages/best-car-pages.module').then(m => m.BestCarPagesModule),
    loadChildren: () => import('./modules/auction-page/auction-page.module').then(m => m.AuctionPageModule),
    data: { title: 'DeeAuction' }
  },
  // {
  //   path: 'auctions',
  //   canActivate: [NoAuthGuard],
  //   component: PublicComponent,
  //   loadChildren: () => import('./modules/best-car-pages/best-car-pages.module').then(m => m.BestCarPagesModule),
  //   // loadChildren: () => import('./modules/auction-page/auction-page.module').then(m => m.AuctionPageModule),
  //   data: { title: 'DeeAuction' }
  // },
  {
    path: 'auction',
    canActivateChild: [NoAuthGuard],
    component: PublicComponent,
    loadChildren: () => import('./modules/auction-detail/auction-detail.module').then(m => m.AuctionDetailModule),
    data: { title: 'DeeAuction' }
  },
  {
    path: 'shop',
    canActivateChild: [NoAuthGuard],
    component: PublicComponent,
    loadChildren: () => import('./modules/shop-page/shop-page.module').then(m => m.ShopPageModule),
    data: { title: 'DeeAuction' }
  },
  // {
  //   path: 'price',
  //   canActivateChild: [NoAuthGuard],
  //   component: PublicComponent,
  //   loadChildren: () => import('./modules/price-page/price-page.module').then(m => m.PricePageModule),
  //   data: { title: '' }
  // },
  {
    path: 'managements',
    canActivate: [AuthGuard],
    component: PublicComponent,
    loadChildren: () => import('./modules/your-management/your-management.module').then(m => m.YourManagementModule),
    data: { title: 'DeeAuction' }
  },
  // {
  //   path: 'about',
  //   canActivate: [NoAuthGuard],
  //   component: PublicComponent,
  //   loadChildren: () => import('./modules/about-page/about-page.module').then(m => m.AboutPageModule),
  //   data: { title: '' }
  // },
  // {
  //   path: 'how-to',
  //   canActivate: [NoAuthGuard],
  //   component: PublicComponent,
  //   loadChildren: () => import('./modules/how-to-page/how-to-page.module').then(m => m.HowToPageModule),
  //   data: {
  //     title: ''
  //   }
  // },
   {
    path: AppURL.account,
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
  },
    {
    path: AppURL.notification,
    component: PublicComponent,
    loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule),
    data: {
      title: ''
    }
  },
  {
    path: 'safe',
    loadChildren: () => import('./modules/safe/safe.module').then(m => m.SafeModule)
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },

];

@NgModule({
  imports: [
    RouterModule.forRoot([...bestRoutes], {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
