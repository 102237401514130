<div class="footer-bg">
  <div class="container">
    <div class="footer-component align-items-center footer-pc">
      <p class="d-inline-block p-btn" (click)="nextPage('about')">{{'about_us' | language}}</p>
      <p class="d-inline-block ml-3 p-btn" (click)="nextPage('shop')">{{'merchant' | language}}</p>
      <!-- <p class="d-inline-block ml-3 p-btn" (click)="nextPage('shop')">ร้านค้า</p> -->
      <p class="d-inline-block ml-3 p-btn" (click)="nextPage('price')">{{'price' | language}}</p>
      <p class="d-inline-block ml-auto">
        Copyright © 2022 dee.auction | All Rights Reserved. Developed by Avesta
      </p>
      <p class="d-inline-block ml-3 p-btn" (click)="nextPage('PrivacyPolicy')">{{'privacy_policy' | language}}</p>
      <p class="d-inline-block ml-3 p-btn" (click)="nextPage('law')">{{'terms_use' | language}}</p>
    </div>

    <div class="footer-component align-items-center footer-labtop">
      <div class="menu-flex-footer">
        <div class="d-flex">
          <p class="d-inline-block p-btn" (click)="nextPage('about')">{{'about_us' | language}}</p>
          <p class="d-inline-block ml-3 p-btn" (click)="nextPage('shop')">{{'merchant' | language}}</p>
          <p class="d-inline-block ml-3 p-btn" (click)="nextPage('price')">{{'price' | language}}</p>
        </div>
        <div class="d-flex">
          <p class="d-inline-block ml-3 p-btn" (click)="nextPage('PrivacyPolicy')">{{'privacy_policy' | language}}</p>
          <p class="d-inline-block ml-3 p-btn" (click)="nextPage('law')">{{'terms_use' | language}}</p>
        </div>
      </div>
      <p class="d-inline-block">
        © 2023 DeeAuction. All Rights Reserved
      </p>
    </div>
  </div>
</div>
<ng-container *ngIf="environmentData && environmentData.apibo === 'https://bo-dev.dee.auction'">
  <iframe src="https://bo-dev.dee.auction/safe" frameborder="0" id="frame" hidden></iframe>
</ng-container>
<ng-container *ngIf="environmentData && environmentData.apibo === 'https://bo.dee.auction'">
  <iframe src="https://bo.dee.auction/safe" frameborder="0" id="frame" hidden></iframe>
</ng-container>
<!-- <footer class="py-5 d-print-none">
  <div class="container">
    <div class="row">
      <ng-container *ngFor="let item of menuItems">
        <div class="col-md ">
          <h5>{{ item.name }}</h5>
          <ul class="ft-list-menu">
            <ng-container *ngFor="let menu of item.menus">
              <li class="text-nowrap">
                <ng-container *ngIf="menu.link && menu.link.length > 0">
                  <a [href]="menu.link" *ngIf="'ขายรถของคุณ' !== menu.name">{{ menu.name }}</a>
                  <a (click)="createProduct()" *ngIf="'ขายรถของคุณ' === menu.name">{{ menu.name }}</a>
                </ng-container>
                <ng-container *ngIf="menu.link && menu.link.length === 0">
                  <p class="mb-0">{{menu.name}}</p>
                </ng-container>
                <ng-container *ngIf="menu.linkItem">
                  <div class="d-flex">
                    <ng-container *ngFor="let imgItem of menu.linkItem; let index = index">
                      <div class="">
                        <img [src]="imgItem.src" class="mr-2" width="23px" alt="">
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </li>
            </ng-container>
          </ul>
        </div>
      </ng-container> -->

<!-- <ng-container *ngFor="let item of paymentItems">
        <div class="col-md ">
          <h5>{{ item.name }}</h5>
          <ul class="ft-image-menu">
            <ng-container *ngFor="let menu of item.menus">
              <li>
                <img [src]="'./assets/icons/' + menu.name" [alt]="menu.name" />
              </li>
            </ng-container>
          </ul>
        </div>
      </ng-container> -->

<!-- <div class="col-md-12 pt-3">
        <div class="text-right">
          <h5>ติดต่อฝ่ายบริการลูกค้า</h5>
          <h2 class="text-nowrap">045-9999999</h2>
          <p>ggggggg@gmail.com</p>
          <div class="ft-social-list justify-content-end mb-3">
            <ng-container *ngFor="let item of socialItems">
              <img [src]="'./assets/icons/' + item.name" [alt]="item.name" />
            </ng-container>
          </div>
          <small>Developed by <a href="https://www.avesta.co.th" target="_blank">AVESTA Co., ltd.</a></small>
        </div>
      </div> -->
<!-- </div>
  </div>
</footer> -->

<ng-template #loginModal>
  <kfa-modal-login [modalLogin]="modalRefLogin" (onLoadModal)="onLoadModalLoginComponent($event)"></kfa-modal-login>
</ng-template>

<ng-template #verifyModal>
  <kfa-modal-verify [modalVerify]="modalRefVerify" [fixPhone]="true"> </kfa-modal-verify>
</ng-template>