import { Component, OnInit, TemplateRef, ViewChild, OnDestroy, Renderer2 } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppService } from 'src/app/app.service';
import { interval, Subscription, timer } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { KEY_USER } from 'src/app/models/app.models';
import { BestNavbarComponent } from 'src/app/core/components/best-navbar/best-navbar.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LanguagePipe } from 'src/app/shared/pipes/language.pipe';

@Component({
  selector: 'kfa-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
  providers: [LanguagePipe]
})
export class PublicComponent implements OnInit, OnDestroy {
  @ViewChild('modalAuctionDetail', { static: true }) modalAuctionDetail: TemplateRef<any>;
  modalRef: BsModalRef;

  Subscriptions: Subscription[] = [];

  /** สำหรับล้างค่าของ verify */
  verifySubscription: Subscription = new Subscription();

  /** สำหรับเก็บ BestNavbarComponent */
  @ViewChild('navbarComponent', { static: true }) navbarComponent: BestNavbarComponent;

  unshowUserMode: boolean = false;

  constructor(
    private modalService: BsModalService,
    private app: AppService,
    private storage: LocalStorageService,
    private renderer: Renderer2,
    private router: Router,
    private actRouter: ActivatedRoute,
    private languageSer: LanguagePipe,
    ) {
    let url = this.router.url;
    if (url) {
      if (url.split('/')[1] === 'managements') {
        this.unshowUserMode = true;
      } else {
        this.unshowUserMode = false;
      }
    }

    let listUrl = url.split('/');
    if (listUrl.length === 2 && listUrl[1] === 'shop') {
      this.router.navigate(['']);
    }

    this.Subscriptions.push(
      this.app.modalDetail.subscribe(res => {
        if (res) {
          this.openModalAuction();
        } else {
          this.closeModal();
        }
      })
    );
  }

  ngOnInit() {
    if (this.app.isBrowser) {
      this.initializeVerifyProcess();
    }
  }

  ngOnDestroy() {
    this.verifySubscription.unsubscribe();
    this.Subscriptions.map(s => s.unsubscribe());
    this.Subscriptions = [];
  }

  openModalAuction() {
    this.modalRef = this.modalService.show(this.modalAuctionDetail, {
      ignoreBackdropClick: false,
      class: 'modal-lg'
    });
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
  }

  /** ตรวจสอบการ verify ของผู้ใช้งาน */
  get getCustomer(): ICustomer { return this.storage.get(KEY_USER) || {}; }

  /** ตรวจสอบ verify แบบ realtime */
  initializeVerifyProcess() {
    const createDomProcess = () => {
      const div = this.renderer.createElement('div') as HTMLDivElement;
      const span = this.renderer.createElement('span') as HTMLSpanElement;
      const reload = this.renderer.createElement('a') as HTMLLinkElement;
      const logout = this.renderer.createElement('a') as HTMLLinkElement;
      reload.href = 'javascript:location.reload()';
      reload.appendChild(this.renderer.createText(this.languageSer.transform('reload_page')));
      logout.href = 'javascript:;';
      logout.appendChild(this.renderer.createText(this.languageSer.transform('logout')));
      span.appendChild(this.renderer.createText(this.languageSer.transform('please_verify_your_identity_before_accessing_system')));
      span.appendChild(this.renderer.createElement('br'));
      span.appendChild(reload);
      span.appendChild(this.renderer.createText(" | "));
      span.appendChild(logout);
      div.className = 'verify-account';
      div.appendChild(span);
      if (this.getCustomer.CustomerStatus !== 'dea') return this.verifySubscription.unsubscribe();
      if (document.body.querySelector('.' + div.className)) return;
      document.body.appendChild(div);
      // on Logout
      logout.onclick = () => {
        this.verifySubscription.unsubscribe();
        this.navbarComponent.onLogout();
        div.remove();
      };
    };
    createDomProcess();
    this.verifySubscription = interval(100).subscribe(() => createDomProcess());
  }
}
