import { LoginService } from './../../../shared/services/login/login.service';
import { ModalLoginComponent } from 'src/app/shared/components/modal-login/modal-login.component';
import { CustomerService } from 'src/app/services/customer.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NavbarNamespace } from 'src/app/interfaces/navbar.interface';
import { version } from '../../../../../package.json';
import Swal from 'sweetalert2';
import { KEY_TOKEN } from 'src/app/models/app.models';
import { environment } from 'src/environments/environment';
import { LanguagePipe } from 'src/app/shared/pipes/language.pipe';

@Component({
  selector: 'best-footer',
  templateUrl: './best-footer.component.html',
  styleUrls: ['./best-footer.component.scss'],
  providers: [LanguagePipe]
})
export class BestFooterComponent implements OnInit {
  public version: string = version;
  customer: ICustomer;

  @ViewChild('verifyModal', { static: true }) verifyModal: TemplateRef<any>;
  @ViewChild('loginModal', { static: true }) loginModal: TemplateRef<any>;
  modalRefVerify: BsModalRef;
  modalRefLogin: BsModalRef;
  environmentData = environment;

  /** เมนูหลักของ Footer */
  menuItems: NavbarNamespace.IMenu[] = [
    {
      name: 'ศูนย์ช่วยเหลือ',
      link: [],
      menus: [
        { name: 'วิธีการสมัครสมาชิก', link: ['how-to/register'] },
        { name: 'วิธียืนยันตัวตนผู้ขาย', link: ['how-to/verify-seller'] },
        { name: 'วิธีการลงประมูล', link: ['how-to/auction'] },
        { name: 'วิธีการชำระเงิน', link: ['how-to/payments'] },
        { name: 'วิธีการจัดส่ง', link: ['how-to/tracking'] }
      ]
    },
    {
      name: 'ตลาดรถ',
      link: [],
      menus: [
        { name: 'รถสำหรับขาย', link: ['search'] },
        { name: 'รีวิวรถใหม่', link: [] },
        { name: 'ขายรถของคุณ', link: ['shop/create-shop'] }
      ]
    },
    {
      name: 'เกี่ยวกับเรา',
      link: [],
      menus: [
        { name: 'เกี่ยวกับเรา', link: ['about'] },
        { name: 'คำถามที่พบบ่อย', link: ['about/faq'] },
        { name: 'นโยบายความเป็นส่วนตัว', link: ['about/privacy-policy'] },
        { name: 'นโยบายการคืนเงิน และคืนสินค้า', link: ['about/refund-policy'] }
      ]
    },
    {
      name: 'ติดต่อฝ่ายบริการลูกค้า',
      link: [],
      menus: [
        { name: 'Tel. 045-999-9999', link: [] },
        { name: 'ggggggg@gmail.com', link: [] },
        { name: 'Add Line : @difirenze', link: [] },
        {
          name: null,
          link: null,
          linkItem: [
            { src: './assets/svg/world.svg' },
            { src: './assets/svg/facebook.svg' },
            { src: './assets/svg/line.svg' },
            { src: './assets/svg/instagram.svg' }
          ]
        }
      ]
    }
  ];

  /** เมนูวิธีการชำระเงิน และรายการภาพ */
  paymentItems: NavbarNamespace.IMenu[] = [
    {
      name: 'วีธีการชำระเงิน',
      link: [],
      menus: [
        { name: 'kbank.png', link: [] },
        { name: 'scb.png', link: [] },
        { name: 'ktb.png', link: [] },
        { name: 'bbl.png', link: [] },
        { name: 'bay.png', link: [] },
        { name: 'gsb.png', link: [] },
        { name: 'paysbay.png', link: [] },
        { name: 'visa.png', link: [] },
        { name: 'master-card.png', link: [] }
      ]
    }
  ];

  /** รายการภาพ Social */
  socialItems: NavbarNamespace.IMenu[] = [
    { name: 'website.png', link: [] },
    { name: 'facebook.png', link: [] },
    { name: 'line.png', link: [] },
    { name: 'ig.png', link: [] }
  ];


  constructor(
    private router: Router,
    private modalService: BsModalService,
    private svCustomer: CustomerService,
    private loginService: LoginService,
    private languageSer: LanguagePipe,
    ) {

  }

  ngOnInit() {
    setTimeout(() => {
      this.svCustomer.Customer$.subscribe(cust => {
        this.customer = cust;
      });
    }, 300);
  }

  /** ไปหน้าสร้างสินค้า */
  createProduct() {
    if (!this.customer) {
      this.loginService.setModalPageName(0);
      this.modalRefLogin = this.modalService.show(this.loginModal, {
        class: 'modal-md transparent'
      });
    }
    if (!this.customer.CustomerPhoneNoVerified) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: this.languageSer.transform('havenot_verified_identity') + '\n' +this.languageSer.transform('via_phone_number'),
        showConfirmButton: false,
        timer: 1500
      });
      this.openModalVerify();
      return;
    }
    if (this.customer.Store) {
      this.router.navigateByUrl(`shop/${this.customer.Store}/create-product`);
    } else {
      this.router.navigateByUrl(`shop/create-shop`);
    }
  }

  openModalVerify() {
    this.modalRefVerify = this.modalService.show(this.verifyModal, {
      class: 'modal-md transparent',
      ignoreBackdropClick: true
    });
  }

  /** โหลดข้อมูล ModalLoginComponent */
  onLoadModalLoginComponent(component: ModalLoginComponent) {
    if (!this.customer) return;
    const { CustomerStatus } = this.customer;
    if (CustomerStatus === 'dea') {
      this.modalRefLogin.hide();
      component.openModalVerify();
    }
  }

  nextPage(nameUrl: string, element?: any) {
    if (nameUrl === 'about') {
      window.open('https://landing.dee.auction/contact/');
      // window.open('https://www.avesta.co.th', "_blank");
    } else if (nameUrl === 'shop' || nameUrl === 'price') {
      let dataToken = localStorage.getItem(KEY_TOKEN);
      if (dataToken) {
          let frame: any;
          frame = document.getElementById('frame');
          frame = frame.contentWindow;
          frame.postMessage({type: 'send', value: dataToken}, this.environmentData.apibo);
      }
      if (nameUrl === 'shop') {
        window.open(this.environmentData.apibo);
      } else {
        window.open(this.environmentData.apibo + '/packages');
      }
    } 
    // else if (nameUrl === 'price') {
    //   this.router.navigate(['', 'price']);
    // } 
    else if (nameUrl === 'PrivacyPolicy') {
      window.open('https://landing.dee.auction/privacy-policy/');
    }else if (nameUrl === 'law') {
      window.open('https://landing.dee.auction/term/');
    }
  }
}
