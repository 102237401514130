import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'kfa-modal-rank',
  templateUrl: './modal-rank.component.html',
  styleUrls: ['./modal-rank.component.scss']
})
export class ModalRankComponent implements OnInit, OnDestroy {
  @Input() shopURL: string = '';
  @Output() Close = new EventEmitter();
  @ViewChild('audioOption', { static: true }) audioPlayerRef: ElementRef<HTMLAudioElement>;

  content = environment.content;

  AuctionWinner: IAuction;
  Subscriptions: Subscription[] = [];

  constructor(private userSv: UserService) {
    this.Subscriptions.push(
      this.userSv.winner.subscribe(res => {
        this.AuctionWinner = res;
      })
    );
  }

  ngOnInit() {
    this.onAudioPlay();
  }

  ngOnDestroy() {
    this.Subscriptions.map(s => s.unsubscribe());
    this.Subscriptions = [];
    this.userSv.setWinner(null);
    this.closeModal();
  }

  onAudioPlay() {
    this.audioPlayerRef.nativeElement.play();
    setTimeout(() => {
      this.audioPlayerRef.nativeElement.pause();
    }, 5000);
  }

  closeModal() {
    this.Close.emit(true);
  }
}
