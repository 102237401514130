import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { LanguagePipe } from '../pipes/language.pipe';
// import { ValidateMessageLists } from 'src/app/configs/validate.config';
interface ErrorMessageClass { [key: string]: string; }

@Component({
  selector: 'best-validate-message',
  templateUrl: './validate-message.component.html',
  styleUrls: ['./validate-message.component.scss']
})
export class ValidateMessageComponent implements OnInit {
  @Input('') control!: AbstractControl;

  /** ให้ระบบใส่ * บน label อัตโนมัติ */
  @Input('requiredLabel') requiredLabel: boolean = true;

  /** แสดง icon หรือไม่ */
  @Input('visibleIcon') visibleIcon: boolean = false;

  ValidateMessageLists: ErrorMessageClass = {
    'min': 'Please enter a number greater than or equal to {0}.',
    'max': 'Please enter a number less than or equal to {0}.',
    'minlength': 'Please enter more than {0} characters.',
    'maxlength': 'Please fill in no more than {0} characters.',
    'required': 'Please fill in this field.',
    'email': 'Please fill in the information in email format.',
    'pattern': 'Please fill in information to match {0}.'
  };

  constructor(
    private _render: Renderer2,
    private _elemRef: ElementRef,
    private languageSer: LanguagePipe,
  ) {
    // console.log(this.ValidateMessageLists);
    this.ValidateMessageLists = {
      'min': this.languageSer.transform('PLEASE_ENTER_A_NUMBER_GREATER_THAN_OR_EQUAL_TO'),
      'max': this.languageSer.transform('PLEASE_ENTER_A_NUMBER_LESS_THAN_OR_EQUAL_TO'),
      'minlength': this.languageSer.transform('PLEASE_ENTER_MORE_THAN_CHARACTERS'),
      'maxlength': this.languageSer.transform('PLEASE_FILL_IN_NO_MORE_THAN_CHARACTERS'),
      'required': this.languageSer.transform('PLEASE_FILL_IN_THIS_FIELD'),
      'email': this.languageSer.transform('PLEASE_FILL_IN_THE_INFORMATION_IN_EMAIL_FORMAT'),
      'pattern': this.languageSer.transform('PLEASE_FILL_IN_INFORMATION_TO_MATCH')
    };
   }

  ngOnInit(): void {
    this._initalizeLoadRequiredLabel();
  }

  /** ตรวจสอบว่า required หรือไม่ */
  get isRequired() { return this.control.hasError('required'); }

  /** แสดงข้อความที่ไม่ถูกต้อง */
  get validateMessage(): string {
    let message = '';
    if ((this.control.touched || this.control.dirty) && this.control.invalid) {
      const errors = this.control.errors;
      const errorKeys = Object.keys(errors || {});
      if (errorKeys.length <= 0) return '';
      const key = errorKeys[0];
      // console.log('test');
      // console.log(this.ValidateMessageLists);
      // console.log(key);
      const value = (errors || {})[key] as any;
      // console.log(value);
      switch (key) {
        case 'min':
        case 'max':
          message = this.ValidateMessageLists[key].replace('{0}', value[key].toString());
          break;
        case 'minlength':
        case 'maxlength':
          message = this.ValidateMessageLists[key].replace('{0}', value.requiredLength);
          break;
        case 'pattern':
          message = this.ValidateMessageLists[key].replace('{0}', value.requiredPattern);
          break;
        case 'vmessage':
          message = value;
          break;
        case 'forbiddenSC':
          message = this.languageSer.transform('CANNOT_USE_SPECIAL_CHARACTERS');
          break;
        case 'forbiddenSCQues':
          message = this.languageSer.transform('SPECIAL_CHARACTERS_ARE_NOT_ALLOWED_EXCEPT');
          break;
        case 'blankText':
          message = this.languageSer.transform('CAN_T_LEAVE_TEXT_BLANK');
          break;
        case 'validatePhoneNumber':
          message = this.languageSer.transform('PLEASE_ENTER_YOUR_PHONE_NUMBER_CORRECTLY');
          break;
        default:
          message = this.ValidateMessageLists[key] || "Something isn't right.";
      }
    }
    return message;
  }

  /** ตรวจสอบการ required label */
  private _initalizeLoadRequiredLabel() {
    if (!this.isRequired) return;
    if (!this.requiredLabel) return;
    if (!this._elemRef.nativeElement) return;
    const currentElem = this._elemRef.nativeElement as HTMLElement;
    const parentElem = currentElem.parentElement;
    if (!parentElem) return;
    const labelElem = parentElem.querySelector('label.title-input');
    if (!labelElem) return;
    this._render.addClass(labelElem, 'required');
  }
}
