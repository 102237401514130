import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { LocalStorageService } from '../core/services/local-storage.service';
import { IAddress } from '../models/model-address.interface';
import { ModelPayment } from '../models/model-userinfo.interface';
import { KEY_USER } from '../models/app.models';
import { IPayment } from '../interfaces/payment';
import { IBank } from '../interfaces/bank';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  Customer$ = new BehaviorSubject<ICustomer>(null);
  updateCustomer$ = new Subject<boolean>();
  shopImage: string;
  shopName: string;
  shopeUrl: string;

  constructor(private http: HttpClient, private localStorage: LocalStorageService) {}

  // #region Customer
  getCustomerInfo() {
    return this.http.get<ICustomer>('customer/info').pipe(
      map(customer => {
        this.localStorage.set(KEY_USER, customer);
        return customer;
      })
    );
  }
  getCustomerExceptInLobby({ page, limit, name, filter, auctionId }: IModelPager) {
    const pageParams = `page=${page}&limit=${limit}&name=${name}&filter=${filter}`;
    return this.http.get<IPagination<ICustomerLobby>>(`customer/except_in_auction/${auctionId}?${pageParams}`);
  }
  UpdateCustomerInfo(item) {
    return this.http.put('customer/update', item);
  }
  // #endregion Customer

  // #region ADDRESS
  getAddressById(addressId: number) {
    return this.http.get<IAddress[]>(`customers/addresses/${addressId}`);
  }
  getAllAddress() {
    return this.http.get<IAddress[]>('customers/addresses');
  }
  deleteAddressById(id: number) {
    return this.http.delete(`customers/addresses/${id}`);
  }
  createAddress(item: IAddress) {
    return this.http.post('customers/addresses', item);
  }
  updateAddress(item: IAddress, id: number) {
    return this.http.put(`customers/addresses/${id}`, item);
  }
  switchAddress(id) {
    return this.http.get(`customers/addresses/${id}/switch`);
  }
  defaultAddress(id) {
    return this.http.post(`customers/addresses/${id}/setdefault`, '');
  }
  // #endregion ADDRESS

  // #region SELLER
  register_Seller(item) {
    return this.http.put('customer/seller_register', item);
  }
  // #endregion SELLER

  // #region PAYMENT
  getListPayments() {
    return this.http.get<IPayment[]>('customers/payment_methods');
  }
  createPayment(item) {
    return this.http.post<ModelPayment>('customers/payment_methods', item);
  }
  updatePayment(item, id) {
    return this.http.put<ModelPayment>(`customers/payment_methods/${id}`, item);
  }
  deletePaymentById(id) {
    return this.http.delete(`customers/payment_methods/${id}`);
  }
  getListBanks() {
    return this.http.get<IBank[]>('banks');
  }
  // #endregion PAYMENT

  // #region OTHER
  changePhone(items) {
    return this.http.put<any>('customer/change_phoneNo', items);
  }
  verify_newPhoneNo(items) {
    return this.http.post('customer/verify_newPhoneNo', items);
  }
  changePassword(items) {
    return this.http.put('customer/change_password', items);
  }
  changeEmail(items) {
    return this.http.put('customer/change_email', items);
  }
  checkeTokenEmail(token) {
    return this.http.get(`customer/verify_newEmail?token=${token}`);
  }

  checkduplicate(username: any) {
    return this.http.get<any>(`customer/username/checkduplicate/${username}`);
  }
  // #endregion OTHER

  //Shop data เอาไปเปลี่ยน menu bar ครับ

  setshopImage(image: string) {
    this.shopImage = image;
  }

  setshopName(image: string) {
    this.shopName = image;
  }

  setshopeUrl(image: string) {
    this.shopeUrl = image;
  }

  getshopImage() {
    return this.shopImage;
  }

  getshopName() {
    return this.shopName;
  }

  getshopeUrl() {
    return this.shopeUrl;
  }
}
