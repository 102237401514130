import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { CountdownComponent } from 'ngx-countdown';
import { LoginService } from '../../services/login/login.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { KEY_TOKEN } from 'src/app/models/app.models';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'kfa-modal-verify',
  templateUrl: './modal-verify.component.html',
  styleUrls: ['./modal-verify.component.scss']
})
export class ModalVerifyComponent implements OnInit {
  @Input() modalVerify: BsModalRef;
  @Input() fixPhone: boolean;

  formChooseVerify: FormGroup;
  formSendOTP: FormGroup;
  activeTab = 'select-verify';
  selectType = 0;

  status = 'start';
  otpTimeOut = false;

  configTime = { leftTime: 600 };

  @ViewChild('countdown', { static: true }) counter: CountdownComponent;

  constructor(
    private builder: FormBuilder,
    private serviceLogin: LoginService,
    private router: Router,
    private storage: LocalStorageService,
    @Inject(WINDOW) private _window: Window
  ) {
    this.setFormChooseVerify();
    this.setFormSendOTP();
  }

  ngOnInit() {}

  setFormChooseVerify() {
    this.formChooseVerify = this.builder.group({
      verifyType: ['', Validators.required],
      token: [this.storage.get(KEY_TOKEN), Validators.required]
    });
  }

  setFormSendOTP() {
    this.formSendOTP = this.builder.group({
      OTPRef: ['', Validators.required],
      OTPCode: ['', Validators.required],
      token: [this.storage.get(KEY_TOKEN), Validators.required]
    });
  }

  chooseTypeVerify() {
    Swal.fire({
      title: 'กำลังโหลดข้อมูล...',
      timer: 700,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    }).then(result => {
      if (this.selectType === 0) {
        this.formChooseVerify.patchValue({
          verifyType: 'sms'
        });
      } else {
        this.formChooseVerify.patchValue({
          verifyType: 'email'
        });
      }

      this.serviceLogin.requestVerify(this.formChooseVerify.value).subscribe(
        res => {
          if (this.selectType === 0) {
            this.formSendOTP.patchValue({
              OTPRef: res.OTPRef
            });
            this.configTime = { leftTime: 300 };
            this.activeTab = 'select-OTP';
          } else {
            if (res === true) {
              this.activeTab = 'select-Email';
            }
          }
        },
        err => {}
      );
    });
  }

  sendOTP() {
    this.serviceLogin.sendOTP(this.formSendOTP.value).subscribe(
      res => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'ยืนยันตัวตนสำเร็จ',
          showConfirmButton: false,
          timer: 1500
        }).then(result => {
          this.storage.set(KEY_TOKEN, `${res.Token}`);

          this.modalVerify.hide();
          this.router.navigate(['']).then(() => {
            this._window.location.reload();
          });
        });
      },
      err => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: `${err.error.Message}`,
          showConfirmButton: false,
          timer: 1500
        }).then(result => {});
      }
    );
  }

  OtpAgain() {
    this.serviceLogin.OTPagain(this.formSendOTP.value.token).subscribe(res => {
      this.formSendOTP.patchValue({
        OTPRef: res.OTPRef,
        OTPCode: ''
      });
      this.configTime = { leftTime: 300 };
      this.otpTimeOut = false;
    });
  }

  finishTimeOTP($event) {
    if ($event.action === 'done') {
      this.otpTimeOut = true;
    }
  }
}
